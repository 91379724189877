export const PlayButtonIcon = (props: any) => {
  return (
    <svg
      {...props}
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_9176_13291)">
        <circle cx="56" cy="54" r="50" fill="#05050D" />
        <path
          d="M72.9648 54.1386C73.6315 54.5235 73.6315 55.4858 72.9648 55.8707L46.617 71.0826C45.9503 71.4675 45.117 70.9864 45.117 70.2166L45.117 39.7927C45.117 39.0229 45.9503 38.5418 46.617 38.9267L72.9648 54.1386Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_9176_13291"
          x="0"
          y="0"
          width="112"
          height="112"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0270981 0 0 0 0 0.0647949 0 0 0 0 0.0331296 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9176_13291"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9176_13291"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
